<template>
  <b-modal
    ref="refCreateQuickSmsModal"
    :title="quickItem.id !== null ? 'EDIT QUICK SMS' : 'NEW QUICK SMS'"
    size="lg"
    :no-close-on-backdrop="true"
    @hidden="closeModal"
  >
    <ValidationObserver ref="form">
      <b-row cols="1">
        <b-col class="mt-2">
          <validation-provider v-slot="{ errors }" name="date" rules="required">
            <b-form-group label="Title" label-cols-md="2">
              <b-form-input
                v-model="quickForm.title"
                :state="errors[0] ? false : null"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col>
          <validation-provider
              v-slot="{ errors }"
              name="module"
              rules="required"
          >
            <b-form-group
                label="Module"
                label-cols-md="2"
            >
              <b-form-select
                  v-model="quickForm.module"
                  text-field="name"
                  :disabled="quickItem.id !== null"
                  value-field="id"
                  :options="creditExpertsModules"
                  :state="errors[0] ? false : null"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col>
          <validation-provider
              v-slot="{ errors }"
              name="date"
              rules="required"
          >
          <b-form-group
            label="Body"
            label-cols-md="2"
          >
              <b-form-textarea
                v-model="quickForm.sms"
                :state="errors[0] ? false : null"
                rows="3"
              />
              <template #description>
                <small tabindex="-1" class="form-text text-danger"
                  >Max: 1000 characters</small
                >
              </template>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
    </ValidationObserver>
    <template #modal-footer>
      <!-- VARS -->
      <b-form-group label="VARS" class="w-100">
        <b-row>
          <b-col sm="3">
            <b-input-group size="sm">
              <b-input-group-prepend is-text>@1</b-input-group-prepend>
              <b-form-input placeholder="FIRST NAME" readonly />
            </b-input-group>
          </b-col>
          <b-col sm="3">
            <b-input-group size="sm">
              <b-input-group-prepend is-text>@2</b-input-group-prepend>
              <b-form-input placeholder="LAST NAME" readonly />
            </b-input-group>
          </b-col>
        </b-row>
      </b-form-group>
      <b-button variant="success" @click="saveQuicksSmsWithImage"
        >SAVE</b-button
      >
    </template>
  </b-modal>
</template>

<script>
import CrmService from "@/views/crm/services/crm.service";
import MessageService from '@/views/ce-digital/sub-modules/settings/views/messages/service/ce-set-messages.service'
import CedDashboardTracking from '@/views/ce-digital/sub-modules/customer-service/views/dashboard-tracking/service/ced-dashboard-tracking'
import {mapGetters} from "vuex";

export default {
  name: "CreateQuickSms",
  props: {
    quickItem: {
      type: Object,
      default: { id: null },
    },
  },
  data() {
    return {
      quickForm: {
        title: null,
        sms: null,
        module: null,
      },
      smsImage: {
        img: "",
        switch: false,
      },
      viewImage: "/assets/images/icons/sms-img.png",
      creditExpertsModules: [],
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    imageShow() {
      return this.viewImage;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  async created() {
    await this.getQuickSms()
    await this.getDigitalSubmodules()
  },
  mounted() {
    this.toggleModal("refCreateQuickSmsModal");
  },
  methods: {
    async getDigitalSubmodules() {
      const data = await CedDashboardTracking.getDigitalSubmodules()
      if (data.status === 200) {
        this.creditExpertsModules = data.data
      }
    },
    async getQuickSms() {
      const data = await MessageService.getQuickSms({ id: this.quickItem.id });
      if (data.status === 200) {
        if (data.data.length > 0) {
          this.quickForm.title = data.data[0].title
          this.quickForm.sms = data.data[0].sms
          this.quickForm.module = data.data[0].modul_id
        }
      }
    },
    async saveQuicksSmsWithImage() {
      const result = await this.$refs.form.validate();
      if (result) {
        try {
          const swal = await this.showConfirmSwal();
          if (swal.isConfirmed) {
            const params = {
              id: this.quickItem.id,
              title: this.quickForm.title,
              sms: this.quickForm.sms,
              user_id: this.currentUser.user_id,
              modul: this.quickForm.module,
            }
            const response = await CrmService.postSaveQuickSms(params)
            if (response.status === 200) {
              this.$emit("refreshTable");
              this.closeModal();
            }
          }
        } catch (e) {
          console.log(e);
        }
      }
    },
    getImage(e) {
      let file = e.target.files[0];
      this.smsImage.img = file;
      this.uploadImage(file);
    },
    uploadImage(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.viewImage = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    switchChange() {
      if (!this.smsImage.switch) {
        this.viewImage = "/assets/images/icons/sms-img.png";
      }
    },
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>

<style scoped>
.image-message {
  max-width: 14rem !important;
  min-width: 14rem !important;
  max-height: 14rem !important;
}
.image-disabled {
  filter: grayscale(100%);
}
</style>
